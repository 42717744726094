import React, { useState } from "react";
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from "../components/seo"
import PrimaryTags from "../components/Index/primaryTags"
import FeedSection from "../components/Index/feedSection"
import PeopleBlock from "../components/Index/peopleBlock";
import ReduxWrapper from "../redux/reduxWrapper";

const UserTemplate = ({ data, pageContext }) => {
  const user = pageContext.user;

  const seoTitle = `${user} stories: news, blogs, & more`;
  const seoDescription = `${user} news, updated daily: articles, podcasts, & more from leading publishers & influencers. Free ${user} resources & information`;

  const [updateLoginModal, setUpdateLoginModal] = useState(true)

  return (
    <Layout
      updateLoginModal={updateLoginModal}
      setUpdateLoginModal={setUpdateLoginModal}
    >
      <Seo title={seoTitle} description={seoDescription} />
      <PrimaryTags primaryTags={data.allPrimaryTags.nodes} />
      <PeopleBlock />
      <FeedSection feedStories={data.allUsers.nodes[0].feedStories} />
    </Layout>
  );
};

const WrappedPage = (props) => (
    <ReduxWrapper element={<UserTemplate {...props} />} />
)
export default WrappedPage;

export const userQuery = graphql`
query($user: String!) {
  allUsers (filter: {username: {eq: $user}}) {
    nodes {
      username
      email
      feedStories {
        id
        description
        exerpt
        url
        primaryTag
        tags
        title
        createdAt
        updatedAt
        lastModifiedDate
        media {
          url
          type
          old_url
          feedMediaType
          title
          length
          nodeName
          old_url
          type
        } 
        feedSource {
          id
          type
          sourceUrl
          favicon
          primaryTag
          createdAt
          updatedAt
        }
      }
    }
  }
  allPrimaryTags (filter: {username: {eq: $user}}) {
    nodes {
      label
    }
  }
}
`;
